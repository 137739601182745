<template>
  <div id="reportingViewDataSet">
    <div class="view-dataSet">
      <mds-layout-grid class="view-dataSet">
        <mds-row>
          <span>
            <router-link :to="{ name: getRouteDetails('/settings/data-sets') }">
              Data Sets
            </router-link>
          </span>
          <span class="view-dataSet-router">
            > View Data Set: {{ viewDataSetName }}</span
          >
        </mds-row>
        <mds-row class="view-dataSet-heading">
          View Data Set: {{ viewDataSetName }}
        </mds-row>
        <mds-row class="view-dataSet-name-heading">
          Data Set Name
        </mds-row>
        <mds-row class="view-dataSet-name">
          {{ viewDataSetName }}
        </mds-row>
        <mds-row class="view-dataSet-border"></mds-row>

        <!-- your selection heading -->
        <mds-row class="data-set-selected-data-type-heading"
          >Your Selection:
          <span class="data-set-selction-heading-margin"
            >{{ selectedDataPointCount }} Data Points</span
          >
          <span> , {{ selectedDataGroupCount }} Data Groups</span>
        </mds-row>
      </mds-layout-grid>

      <!-- selected Data Point -->
      <template v-if="showAllDataPoint.length > 0">
        <mds-list-group>
          <mds-list-group-item sublist toggle-active text="Data Point">
            <div class="view-dataSet-list-group-border"></div>

            <mds-list-group
              class="view-dataSet-data-point-list"
              v-for="(datapoint, index) in showAllDataPoint"
              :key="index"
            >
              <!-- data Point with only name -->
              <div v-if="datapoint.langcode.length == 0">
                <span class="view-dataSet-list-group-name">{{
                  datapoint.name
                }}</span>
                <div class="view-dataSet-list-group-border"></div>
              </div>

              <!-- data Point with language -->
              <div
                v-for="(value, valIndex) in datapoint.langcode"
                :key="valIndex"
              >
                <span class="view-dataSet-list-group-name">{{
                  datapoint.name
                }}</span>
                <span> ( {{ value }} )</span>
                <div class="view-dataSet-list-group-border"></div>
              </div>
            </mds-list-group>
          </mds-list-group-item>
        </mds-list-group>
      </template>

      <!-- selected Data Group -->
      <template v-if="showAllDataGroup.length > 0">
        <mds-list-group>
          <mds-list-group-item sublist toggle-active text="Data Group">
            <div class="view-dataSet-list-group-border"></div>
            <mds-list-group
              v-for="(datagroup, index) in showAllDataGroup"
              :key="index"
            >
              <span class="view-dataSet-list-group-name">{{
                datagroup.name
              }}</span>
              <div
                v-if="index + 1 < showAllDataGroup.length"
                class="view-dataSet-list-group-border"
              ></div>
            </mds-list-group>
          </mds-list-group-item>
        </mds-list-group>
      </template>
    </div>
    <loader-component v-if="showLoader"></loader-component>
    <notification-component
        v-if="showNotification"
        :notificationMessage="notificationMessage"
        :notificationType="notificationType"
    ></notification-component>
  </div>
</template>

<script>
import LoaderComponent from "../ui_component/loaderComponent.vue";
import { MdsLayoutGrid, MdsRow } from "@mds/layout-grid";
import { MdsListGroup, MdsListGroupItem } from "@mds/list-group";
import { mapActions, mapGetters } from "vuex";
import NotificationComponent from "../ui_component/notificationComponent.vue";

export default {
  name: "ReportingViewDataSet",
  components: {
    LoaderComponent,
    MdsLayoutGrid,
    MdsRow,
    MdsListGroup,
    MdsListGroupItem,
    NotificationComponent

  },
  data() {
    return {
      showLoader: true,
      viewDataSetName: "",
      selectedDataPointCount: 0,
      selectedDataGroupCount: 0,
      showAllDataPoint: [
        // {
        //   id: 1,
        //   name: "Data Point 1",
        //   langcode: ["india", "usa", "uk"],
        // },
      ],
      showAllDataGroup: [
        // {
        //   id: 1,
        //   name: "Data Group 1",
        // }
      ],
      showNotification: false,
    };
  },

  async mounted() {
    const dataSetId = this.$route.query.dataSetId;
    if (dataSetId) {
      this.editMode = true;
      this.isEditModeOn = false;
      await this.fetchDataSetDataPonitList(dataSetId);
      this.viewDataSet = this.getDataSetDataPointList.data;
      if(this.getDataSetDataPointList.isError == false){
          this.viewDataSetName = this.viewDataSet.datasetname;
          this.selectedDataPointCount = this.viewDataSet.singledatapointcount;
          this.selectedDataGroupCount = this.viewDataSet.groupdatapointcount;
          this.showAllDataGroup = this.viewDataSet.groupdatapoints;
          this.showAllDataPoint = this.viewDataSet.singledatapoints;
      } else{
          this.notificationType = "error";
          this.notificationMessage = this.viewDataSet.message;
          this.showNotification = true;
          this.showLoader = false;
          setTimeout(() => {
              this.showNotification = false;
              this.$router.push({ name: "Reporting Data Sets" });
          }, 2000)
      }
    }
    this.showLoader = false;
  },

  computed: {
    ...mapGetters("reporting", ["getDataSetDataPointList"]),
  },

  methods: {
    ...mapActions("reporting", ["fetchDataSetDataPonitList"]),
    getRouteDetails(url) {
      const routeDetails = this.$router.resolve({
        path: url,
      });
      return routeDetails.resolved.name;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/styles.scss";

.view-dataSet {
  padding: 16px 16px 0 16px;
}

.view-dataSet-router {
  margin-left: $mds-space-half-x;
}

.view-dataSet-heading {
  margin-top: $mds-space-2-x;
  margin-bottom: $mds-space-2-x;
  height: 36px;
  left: 16px;
  top: 50px;
  font-style: normal;
  font-weight: 250;
  font-size: 32px;
  line-height: 36px;
  color: #1e1e1e;
}

.view-dataSet-name-heading {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
}

.view-dataSet-name {
  margin-top: 20px;
  margin-bottom: $mds-space-1-x;
}

.view-dataSet-border {
  border-bottom: 2px solid #333333;
  height: 0px;
}

.data-set-selected-data-type-heading {
  padding-left: 0px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: $mds-space-3-x;
}

.data-set-selction-heading-margin {
  margin-left: $mds-space-half-x;
}

.view-dataSet-list-group-name {
  margin-left: 16px;
}

.view-dataSet-list-group-border {
  border-bottom: 1px solid #e5e5e5;
}
</style>
